import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import swal from 'sweetalert';
import { CardserviceService } from 'app/services/cardservice.service';
import { DatePipe } from '@angular/common'
// import { randomBytes } from 'crypto';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-card-game',
  templateUrl: './card-game.component.html',
  styleUrls: ['./card-game.component.css']
})
export class CardGameComponent implements OnInit {
  today: number = Date.now();
  title = 'json-file-read-angular';
  selectedData: any = [];
  selectedCardContent: any = [];
  CardDescription: any = [];

  constructor(private httpClient: HttpClient, private cardSvc: CardserviceService,private router: Router, public datepipe: DatePipe) {
  }
  status: boolean = false;
  CardArray = [];
  localarray = [];
  activatedCardArray = [];
  activatedDateArray = [];
  Sep_CardArray;
  gameactivated: boolean = false;
  cardactivate1: boolean = false;
  step;
  showDescription: boolean = false;
  canDrawToday: boolean = false;
  todaydate;
  CurrentUserMail;
  profileData;
  ngOnInit(): void {

    if (localStorage.getItem('currentUser')) {
      this.CurrentUserMail = localStorage.getItem('currentUser');
    }
    else {
      this.router.navigate(['/signup']);
    }
  }
  showcardinRow() {
    this.status = !this.status;
  }

  activategame() {
    this.checkfortoday();
  }

  activatecard() {
    this.showDescription = false;
    this.cardactivate1 = true;
    this.gameactivated=true;
  }

  checkfortoday() {
    var arr = { email: this.CurrentUserMail };
    this.cardSvc.getprofile(arr).subscribe(data => {
      var profilearr = data.data;
      this.profileData = profilearr[0];

      var latest_date = new Date();
      this.todaydate = this.datepipe.transform(latest_date, 'dd-MM-yyyy');

      if (this.profileData.last_opened_date === this.todaydate) {
        swal("Sorry!", "You have drawn today's card, we invite you for drawing a new card tomorrow");

    //testing starts
        // swal({
        //   title: "Are you sure?",
        //   text: "Need to draw a one more card",
        //   icon: "warning",
        //   dangerMode: true,
        //   buttons: ["No", true],
        // })
        // .then((willDelete) => {
        //   if (willDelete) {
        //     this.proceedtoPlay(this.profileData);
        //   } else {
        //     swal("Your card is safe for tomorrow");
        //   }
        // });
        // Testing end
      }
      else {
        this.proceedtoPlay(this.profileData);
      }

    })
  }

  proceedtoPlay(userdetails) {

    var arr = { email: this.CurrentUserMail };
    this.cardSvc.getprofile(arr).subscribe(data => {
      var profilearr = data.data;
      this.profileData = profilearr[0];
    })

  var cardnumber = this.getCardnumber(this.profileData);
  
    var carddata = { 
      product_id:cardnumber,
      user_id:this.profileData.id,
      email: this.CurrentUserMail,
      last_opened_date:this.todaydate
    };
    // this.cardSvc.carddetails(carddata).subscribe(data => {

    //   if(data.status==true){
    //     this.httpClient.get("assets/cardcontent.json").subscribe(data => {
    //       console.log(data);
    //       this.selectedData = data;
    //       var cardvalues = cardnumber % 10;
    //       this.selectedCardContent = this.selectedData[cardvalues];
    //       this.CardDescription = this.selectedCardContent.description
    //       console.log(this.CardDescription);
    //       this.activatecard();
    //     })
    //   }
    
    //   card success
      

    // })
  }

  getCardnumber(CardprofileData) {

    this.profileData=CardprofileData;
    var openedCards = this.profileData.card_open

    var cardvalue = Math.floor(Math.random() * (7006 - 7000 + 1) + 7000);
    if(this.profileData.card_open){
      
      if (openedCards.includes(cardvalue)) {
        // alert("exists")
        this.getCardnumber(CardprofileData);
      }
      else{
        this.router.navigateByUrl('/todayscard/'+[cardvalue]);
        // return cardvalue; 
      }
    }
    else{
      this.router.navigateByUrl('/todayscard/'+[cardvalue]);
    }

      
  }

  showdesc() {
    this.showDescription = true;
  }



}
